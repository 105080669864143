import request from '../../utils/request'

export default class CertificateRequest {
  // 获取列表
  async listPost (data) {
    return request.post('/Business/Tool/GetCertificate')
  }

  // 编辑操作
  async editPost (data) {
    return request.post('/Business/Tool/SaveCertificate', data)
  }
}
