import BaseController from '@/service/BaseService'
import CertificateRequest from '@/request/Business/CertificateRequest'
import { errorTip } from '@/utils/Tip'

const base = new BaseController()

export default {
  data () {
    return {
      list: [],
      message: '',
      fullscreenLoading: false
    }
  },
  // 初始化函数
  created: async function () {
    await base.init(this, new CertificateRequest())
  },
  methods: {
    // 获取未配置证书的站点
    GetList: async function () {
      this.fullscreenLoading = true
      const result = await base.request.listPost()
      this.fullscreenLoading = false
      if (result.code !== 200) errorTip(this, result.message)
      this.list = result.data
    },
    // 保存证书
    saveCertificateClick: async function () {
      const webSite = this.list
      if (webSite.length === 0) errorTip(this, '未导入网站')
      this.fullscreenLoading = true

      let delay = 0
      const messages = []
      for (let i = 0; i < webSite.length; i++) {
        await new Promise((resolve) => {
          setTimeout(async () => {
            try {
              const result = await base.request.editPost(webSite[i])
              messages.push(result.message)
              console.log(`处理 ${webSite[i]} 的结果:`, result.message)
            } catch (error) {
              messages.push('请求失败')
              console.error(`处理 ${webSite[i]} 失败:`, error)
            }
            delay = i * 2000 // 更新延迟时间
            resolve()
          }, delay) // 设置延迟时间
        })
      }
      this.fullscreenLoading = false
      this.message = messages.join('\r\n')
    }
  }
}
