<template>
  <div class="manage">
    <!-- 搜索区域 -->
    <div class="manage-header">
      <el-button @click="GetList" size="mini" type="primary" v-loading.fullscreen.lock="fullscreenLoading">网站列表</el-button>
      <el-button @click="saveCertificateClick" size="mini" type="primary">配置证书</el-button>
    </div>

    <div class="center-container">
      <!-- 表格 -->
      <el-table
        :data="list"
        style="width: 80%;"
        border
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column prop="id" label="编号"/>
        <el-table-column prop="name" label="官网"/>
      </el-table>

      <el-input v-model="message" style="width: 20%;" type="textarea" :autosize="{ minRows: 30 }"></el-input>
    </div>
  </div>
</template>

<script>
import Service from '@/service/Business/CertificateService'

export default Service
</script>

<style lang="less" scoped>
.manage {
  height: 90%;

  .manage-header {
    width: 10vw;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}

.center-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>
